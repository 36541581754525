const accordion = document.querySelector(".accordion");
if (accordion) {
  accordion.addEventListener("click", (e) => {
    const activePanel = e.target.closest(".accordion-panel");
    if (!activePanel) return;
    toggleAccordion(activePanel);
  });

  function toggleAccordion(panelToActivate) {
    const activeButton = panelToActivate.querySelector("button");
    const activePanel = panelToActivate.querySelector(".accordion-content");
    const activePanelIsOpened = activeButton.getAttribute("aria-expanded");

    if (activePanelIsOpened === "true") {
      panelToActivate
        .querySelector("button")
        .setAttribute("aria-expanded", false);

      panelToActivate
        .querySelector(".accordion-content")
        .setAttribute("aria-hidden", true);
    } 
    else {
      panelToActivate.querySelector("button").setAttribute("aria-expanded", true);

      panelToActivate
        .querySelector(".accordion-content")
        .setAttribute("aria-hidden", false);
    }
  }
}

/* accordions mit schliesse-die-anderen-funktion */
/*
const accordions = document.querySelectorAll(".accordion");

if (accordions) {
  accordions.forEach((accordion) => {
    accordion.addEventListener("click", (e) => {
      const activePanel = e.target.closest(".accordion-panel");
      if (!activePanel) return;

      accordions.forEach((otherAccordion) => {
        const otherActivePanel = otherAccordion.querySelector(
          ".accordion-panel.active"
        );
        if (otherActivePanel && otherActivePanel !== activePanel) {
          toggleAccordion(otherActivePanel);
        }
      });

      toggleAccordion(activePanel);
    });
  });

  function toggleAccordion(panelToToggle) {
    const activeButton = panelToToggle.querySelector("button");
    const activePanel = panelToToggle.querySelector(".accordion-content");
    const isActive = panelToToggle.classList.contains("active");

    if (isActive) {
      activeButton.setAttribute("aria-expanded", false);
      activePanel.setAttribute("aria-hidden", true);
      panelToToggle.classList.remove("active");
    } else {
      activeButton.setAttribute("aria-expanded", true);
      activePanel.setAttribute("aria-hidden", false);
      panelToToggle.classList.add("active");
    }
  }
}
*/