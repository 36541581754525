
import '@glidejs/glide/dist/glide.min.js';
import './countup';
import './accordion';
//import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'

window.addEventListener('scroll', function () {
  var body = document.body;
  body.classList.toggle('scrolled', window.scrollY > 540);
});

/* new burger */
const tham = document.querySelector(".tham");
const navContent = document.getElementById("navbar");
const mainContent = document.getElementById("main");

tham.addEventListener('click', () => {
  tham.classList.toggle('tham-active');
  navContent.classList.toggle("open");
  mainContent.classList.toggle("blurred");
});
/* youtube warning*/
/*
if(document.querySelector(".yt-consent ")) {
const ytbtn = document.querySelector(".yt-consent ");
const warning  = document.getElementById("warning");

ytbtn.addEventListener('click', () => {
  tham.classList.toggle('tham-active');
  warning.classList.toggle("hidden");
});
}
*/

// glide.js 
const projekte = document.querySelector('.projekteslider');
const header = document.querySelector('.headerslider');

if (projekte) {
  var projekteslider = new Glide('.projekteslider', {
    type: 'carousel',
    autoplay: 3500,
    perView: 3,
    dots: '#dots',
    gap: 10,
    breakpoints: {
      1024: {
        perView: 2
      },
      768: {
        perView: 1
      }
    }
  });
  projekteslider.mount();
}

if (header) {
  var headerslider = new Glide('.headerslider', {
    type: 'carousel',
    autoplay: 5000,
    perView: 1,
    gap: 0,
    animationTimingFunc: 'ease-in-out',
    animationDuration: 800,
  });
  headerslider.mount();
}
// video

let player = document.getElementById("player"),
  play = document.getElementById("play");

if (player) {
  play.addEventListener("click", function () {
    player.play();
    this.style.display = 'none';
  });

  const myVideo = document.getElementById('player');

  function toggleControls() {
    if (myVideo.hasAttribute('controls')) {
      myVideo.removeAttribute('controls');
    } else {
      myVideo.setAttribute('controls', 'controls');
    }
  }

  myVideo.addEventListener('mouseenter', toggleControls);
  myVideo.addEventListener('mouseleave', toggleControls);
}
